// import { Link } from "gatsby" /* eslint-disable */
import PropTypes from "prop-types"
import React, { useContext, useState, useEffect } from 'react'
import StoreContext from '../context/store'

// import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";
// import times from "lodash/times";

import useLayoutQuery from '../hooks/use-layout-query'

import Marquee from "react-fast-marquee";

import Link from '../utils/link'
import { Close, Logo, Search, Spacer } from '../components/icons'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faShoppingBag, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  });
  return quantity
}

const Header = ({ siteTitle }) => {

  const { wp } = useLayoutQuery()

  let headerBanner = wp.siteOptions.acf.headerBanner;

  const context = useContext(StoreContext)
  const { checkout } = context.store
  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))
  const [modal, setModal] = useState(false)
  const [search, setSearch] = useState("")
  const [isSearchActive, setIsSearchActive] = useState(false); // used for search

  const toggleSearchActive = () => {
    setIsSearchActive(!isSearchActive);
  }

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  const openSearchBar = () => {
    setModal(true)
  }

  const closeSearchBar = () => {
    setModal(false)
  }

  const [scrollDirection, setScrollDirection] = useState('none'); // used for scroll
  const [scrolled, setScrolled] = useState(false);  // used for scroll

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrolled = scrollTop >= 50;
      let direction = "none";

      if (scrollTop >= 600) {
        if (scrollTop > lastScrollTop) {
          direction = "down";
        } else {
          direction = "up";
        }
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      setScrollDirection(direction);
      setScrolled(scrolled);
    };

    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let headerClass = 'header';

  if (headerBanner === 'enable') {
    headerClass = 'header marquee-active';
  } else {
    headerClass = 'header';
  }
  
  if (scrollDirection !== 'none') {
    headerClass += ` scrolled-${scrollDirection} ${scrolled}`;
  } else {
    headerClass += ` ${scrolled}`;
  }

  if (scrolled === true) {
    headerClass += ` ${scrolled}`;
  }


  const [offCanvas, setOffCanvas] = useState(false);
  const [showSubnav, setShowSubnav] = useState({
    news: false,
    features: false,
  });

  const hideOffCanvas = () => {
    setOffCanvas(false);
  };

  const toggleOffCanvas = () => {
    setOffCanvas(!offCanvas);
  };

  function toggleSubnav(key) {
    setShowSubnav({ ...showSubnav, [key]: !showSubnav[key] });
  }

  const props = {
    onClick: hideOffCanvas,
    activeClassName: 'active',
  };



  const bannerItems = new Array(15).fill().map((_, index) => (
    <div className="marquee-item" key={index}>
      {wp.siteOptions.acf.firstBannerText && (
        <>
          {wp.siteOptions.acf.firstUrlLink ? (
            <a href={wp.siteOptions.acf.firstUrlLink}>
              <span className="first">{wp.siteOptions.acf.firstBannerText}</span>
            </a>
          ) : (
            <span className="first">{wp.siteOptions.acf.firstBannerText}</span>
          )}
        </>
      )}
  
      <span className="spacer"><Spacer /></span>
  
      {wp.siteOptions.acf.firstBannerText && (
        <>
          {wp.siteOptions.acf.firstUrlLink ? (
            <a href={wp.siteOptions.acf.secondUrlLink}>
              <span className="second">{wp.siteOptions.acf.secondBannerText}</span>
            </a>
          ) : (
            <span className="second">{wp.siteOptions.acf.secondBannerText}</span>
          )}
        </>
      )}
    </div>
  ));

  

  return (
    <>
  
    { headerBanner === 'enable' &&
      <div className="marquee-conatiner">
        <Marquee
          gradient={false}
        >
          {bannerItems}
        </Marquee>
      </div>
    }

    <header className={headerClass}>
      <div className='header__inner'>

        <div className='header__col'>
          <Link to='/' title='CirleZeroEight' className='header__logo active' >
            <Logo color={'#fff'} />
          </Link>
        </div>

        <div className='header__col'>
          <Link to='/' title='CirleZeroEight' className='header__logo txt' >
            CircleZeroEight
          </Link>
        </div>

        <div className='header__col'>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/news' >News</Link></li>
                <li><Link to='/features' >Features</Link></li>
                <li><Link to='/about' >About</Link></li>
                <li><Link to='/shop/current-issue/' >Shop</Link></li>

                {/* <li><Link to='/cart' ><span>Cart ({cartCount})</span></Link></li> */}
                <li>
                  <Link aria-label="cart" to="/cart">
                    {
                      quantity > 0 ?
                      <>
                        <div className="shopping-bag-quantity">Cart ({quantity})</div>
                      </>
                        :
                        <div className="shopping-bag-quantity">Cart (0)</div>
                    }
                  </Link>
                </li>


                <li>
                  <button
                    className={`header__search-icon ${isSearchActive ? 'active' : ''}`}
                    onClick={toggleSearchActive}
                  >
                    <Search color={'#fff'} />
                  </button>

                  <form 
                  className={`header__search ${isSearchActive ? 'active' : ''}`}
                  action="/search" 
                  _lpchecked="1">
                    <input type="text" name="search" placeholder="Search"  />
                    <button type="submit">
                      <Search color={'#fff'} />
                    </button>
                  </form>
                </li>
              </ul>
            </nav>

            <button
              onClick={toggleOffCanvas}
              className={
                offCanvas ? 'header__hamburger active' : 'header__hamburger'
              }
            >
               <span className='lines'></span>
            </button>
        </div>
      </div>
    </header>


    <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <button 
                onClick={toggleOffCanvas}
                className="off-canvas__close">
                  <Close color={'#000'} />
              </button>
              <ul>
                <li>
                  {/* <Link to='/news' {...props}>News</Link> */}
                  <Link to='/news'{...props}>News</Link>

                  {/* <Link to='#' onClick={() => toggleSubnav('news')}>News</Link> */}
                    {/* <ul className={`subnav ${showSubnav.news && 'subnav--active'}`}>
                      <li>
                        <Link to='/news'{...props}>All News</Link>
                      </li>
                      <li>
                        <Link to={`/news/new-products`} {...props}>New Products</Link>
                      </li>
                      <li>
                        <Link to={`/news/events`} {...props}>Events</Link>
                      </li>
                      <li>
                        <Link to={`/news/sporting-calendar`} {...props}>Sporting Calendar</Link>
                      </li>
                      <li>
                        <Link to={`/news/sports-news`} {...props}>Sports News</Link>
                      </li>
                    </ul> */}
                </li>
                <li>
                  <Link to='/features'{...props}>Features</Link>

                  {/* <Link to='#' onClick={() => toggleSubnav('features')}>Features</Link> */}
                  {/* <ul className={`subnav ${showSubnav.features && 'subnav--active'}`}>
                    <li>
                      <Link to='/features'{...props}>All Features</Link>
                    </li>
                    <li>
                      <Link to={`/features/sport`} {...props}>Sport</Link>
                    </li>
                    <li>
                      <Link to={`/features/style`} {...props}>Style</Link>
                    </li>
                    <li>
                      <Link to={`/features/culture`} {...props}>Culture</Link>
                    </li>
                  </ul>  */}

                </li>
                <li><Link to='/about' {...props}>About</Link></li>
                <li><Link to='/shop/current-issue/' {...props}>Shop</Link></li>
                <li>
                  <Link aria-label="cart" to="/cart">
                    {
                      quantity > 0 ?
                      <>
                        <div className="shopping-bag-quantity">Cart ({quantity})</div>
                      </>
                        :
                        <div className="shopping-bag-quantity">Cart (0)</div>
                    }
                  </Link>
                </li>
                {/* <li><Link to='/search' {...props}>Search</Link></li> */}
              </ul>
            </nav>

          </div>
        </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
