import React from 'react';
import { StaticQuery, graphql } from 'gatsby'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from "../components/header"
import Footer from "../components/footer"
import Provider from "../context/provider"


const Layout = ({ children, location, pageContext }) => {
    const data = layoutQuery()

    return (
        <Provider>
          <>
              <Header
              siteTitle={data.site.siteMetadata.title}
              data={data}
              pageContext={pageContext}
              />
              <main className='bg-blend blend'>{ children }</main>
              <Footer />
          </>
        </Provider>
    );
};

export default Layout;

