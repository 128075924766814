import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      title
      slug
      uri
      date(formatString: "DD MMM YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      acf {
        excerpt
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        generalSettings {
          title
          url
        }
        siteOptions {
          acf {
            generalEnquiries
            instagram
            prEnquiries
            advertisingSpecialProjects
            headerBanner
            firstBannerText
            firstUrlLink
            secondBannerText
            secondUrlLink
          }
        }
        advertising {
          acf {
            brandName
            advertismentLink
            landscapeImageDesktop {
              altText
              publicUrl
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2880)
                }
              }
            }
            landscapeImageMobile {
              altText
              publicUrl
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2800)
                }
              }
            }
            brandNameTwo
            advertismentLinkTwo
            landscapeImageDesktopTwo {
              altText
              publicUrl
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2880)
                }
              }
            }
            landscapeImageMobileTwo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2800)
                }
              }
            }
          }
        }
      }
    }
  `)
}
export default useLayoutQuery