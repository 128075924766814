import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="112" height="32" viewBox="0 0 112 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80.0548 14.6688C80.0548 14.697 80.0312 14.7206 80.0007 14.7206C79.9701 14.7206 79.9466 14.697 79.9466 14.6688C79.3116 10.854 75.9978 7.94473 72.002 7.94473C68.0062 7.94473 64.6925 10.854 64.0574 14.6688C64.0574 14.697 64.0339 14.7206 64.0034 14.7206C63.9728 14.7206 63.9493 14.697 63.9493 14.6688C63.2719 6.45598 56.3928 3.25704e-06 48.006 3.62363e-06C39.6193 3.99023e-06 32.7378 6.45598 32.0605 14.6688C32.0605 14.6994 32.0346 14.7229 32.004 14.7229C31.9735 14.7229 31.9499 14.6994 31.9476 14.6688C31.2703 6.45598 24.3911 4.65588e-06 16.002 5.02258e-06C7.16375 5.40891e-06 -1.08562e-06 7.1639 -6.99382e-07 16C-3.13144e-07 24.8361 7.16375 32 15.9997 32C24.8356 32 31.2679 25.544 31.9452 17.3312C31.9452 17.3006 31.9711 17.2771 32.0017 17.2771C32.0323 17.2771 32.0558 17.3006 32.0581 17.3312C32.7331 25.544 39.6123 32 47.999 32C56.3857 32 63.2672 25.544 63.9422 17.3312C63.9422 17.303 63.9657 17.2794 63.9963 17.2794C64.0269 17.2794 64.0504 17.303 64.0504 17.3312C64.6854 21.146 67.9992 24.0553 71.995 24.0553C75.9908 24.0553 79.3045 21.146 79.9395 17.3312C79.9395 17.303 79.963 17.2794 79.9936 17.2794C80.0242 17.2794 80.0477 17.303 80.0477 17.3312C80.7321 25.544 87.6113 32 96.0003 32C104.836 32 112 24.8361 112 16C112 7.1639 104.836 1.13951e-06 96.0003 1.52574e-06C87.1644 1.91197e-06 80.7321 6.45598 80.0548 14.6688ZM47.999 24.0012C43.5799 24.0012 39.998 20.4192 39.998 16C39.998 11.5808 43.5799 7.99883 47.999 7.99882C52.4181 7.99882 56 11.5808 56 16C56 20.4192 52.4181 24.0012 47.999 24.0012Z" fill={color}/>
      </svg>
    )
  }
}

class Search extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="6" stroke="white" strokeWidth="4"/>
        <rect x="6" y="15" width="4" height="4" fill={color}/>
      </svg>
    )
  }
}

class Close extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
        <rect x="10.5615" width="3.44693" height="14.9367" transform="rotate(45 10.5615 0)" fill={color}/>
        <rect x="13" y="10.5618" width="3.44693" height="14.9367" transform="rotate(135 13 10.5618)" fill={color}/>
      </svg>
    )
  }
}

class Spacer extends Component {
  render() {
    return (
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
        <circle cx="4" cy="4" r="3.5" stroke="black"/>
      </svg>
    )
  }
}


class DownArrow extends Component {
  render() {
    return (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_76_806)">
      <path d="M19 2L11 10L3 2" stroke="white" strokeWidth="2"/>
      </g>
      <defs>
      <filter id="filter0_d_76_806" x="0.292969" y="0.292908" width="21.4141" height="14.1213" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_806"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_806" result="shape"/>
      </filter>
      </defs>
    </svg>
    )
  }
}



export { Logo, Search, Close, DownArrow, Spacer }
