import React, { useState, useEffect } from 'react';
import Link from '../utils/link'
import useLayoutQuery from '../hooks/use-layout-query'

import NewsletterForm from '../components/newsletter-form'

const Footer = () => {

  const { wp } = useLayoutQuery()

  // const [isOpen, setIsOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const openModal = () => {
    setIsModalOpen(false);
  }

  const closeModal = () => {
    // const modal = document.querySelector('.sidecart__modal');
    // modal.style.opacity = 0;
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!isModalOpen) {
        document.body.classList.add('modal-open');
        document.querySelector('.header').classList.add('active');
        document.querySelector('main').classList.add('active');

        const marquee = document.querySelector('.marquee-conatiner');
          if (marquee) {
            marquee.classList.add('active');
          }
    } else {
        document.body.classList.remove('modal-open');
        document.querySelector('.header').classList.remove('active');
        document.querySelector('main').classList.remove('active');

        const marquee = document.querySelector('.marquee-conatiner');
        if (marquee) {
          marquee.classList.remove('active');
        }
    }
}, [isModalOpen]);

  return (
    <>


      <div className={`newsletter__form ${isModalOpen ? 'notactive' : 'active'}`}>
          <div className='newsletter__form-wrapper'>
          <div className='newsletter__form-container'>
              <div className='newsletter__header'>
                <h4>Sign up to our Newsletter</h4>
              </div>
               <NewsletterForm/>

              <button
                className='btn btn--cancel'
                type='button'
                onClick={closeModal}
              >
                 <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0.353553" y1="0.649376" x2="20.3536" y2="20.6494" stroke="black"/>
                  <line x1="20.3536" y1="1.35648" x2="0.353554" y2="21.3565" stroke="black"/>
                </svg>
              </button>

            </div>
          </div>
        </div>


      <footer className='footer'>

        <div className='footer__inner'>
          <div className='footer__col'>
            <nav className='footer__nav'>
              <ul>
                <li><Link to='/' title='CircleZeroEight' className='footer__logo'>CircleZeroEight</Link></li>
                <li><Link to='/about/#contact'>Contact</Link></li>
                { wp.siteOptions?.acf.instagram?.length > 0 && <li><Link to={wp.siteOptions.acf.instagram}>Instagram</Link></li> }
                <li><button className='btn' onClick={openModal}>Subscribe</button></li>
              </ul>
            </nav>
          </div>

          <div className='footer__col'>
            <nav className='footer__nav'>
              <ul className='social'>
                  <li></li>
                  <li><Link to='/terms'>Terms</Link></li>
                  <li>&copy; CircleZeroEight {new Date().getFullYear()}</li>
                  <li>Site by&nbsp;<Link to='https://studiosmall.com'>StudioSmall</Link></li>
                </ul>
              </nav>

          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
