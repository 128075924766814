exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-ol-dcart-js": () => import("./../../../src/pages/OLDcart.js" /* webpackChunkName: "component---src-pages-ol-dcart-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-features-category-js": () => import("./../../../src/templates/features-category.js" /* webpackChunkName: "component---src-templates-features-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */)
}

